import api from './constants'
import axios from "axios";

const state = {
    packages: {},
    packageImages: {},
    hazardLevels: {},
    packageTypes: {},
}
const mutations = {
    setPackages: (state, value) => state.packages = value,
    setPackageImages: (state, value) => state.packageImages = value,
    setHazardLevels: (state,value)=> state.hazardLevels = value,
    setPackageTypes: (state,value)=>state.packageTypes = value,
}
const actions = {
    async loadPackages({commit}) {
        const config = {
            url: api.api + 'package/items/',
            method: "GET",
            headers: {
                "Authorization": `Token ${localStorage.token}`
            }
        }
        let packages = await axios(config)
        if (packages.status == 200) {
            let res = [...packages.data.results]
            if (res.length > 0) {
                let packages = {}
                for (const e of res) {
                    packages[e.uid] = e
                }
                let packageImages = {}
                for(let key in packages){
                    await axios({
                        url: api.api + `package/img/${key}/`,
                        method: "GET",
                        headers: {
                            "Authorization": `Token ${localStorage.token}`
                        }
                    }).then((e)=>{
                        const images =  e.data
                        packageImages[key] = images
                    })
                }
                commit('setPackages', packages)
                commit('setPackageImages', packageImages)
            }
        }
    },
    async loadHazards({commit}){
        const config = {
            url: api.api + `package/hazards`,
            method: "GET",
            headers: {
                "Authorization": `Token ${localStorage.token}`
            }
        }
        await axios(config).then((r)=>{
            let d = r.data
            let levels = {}
            d.forEach((h)=>{
                levels[h.uid] = h
            })
            commit('setHazardLevels',levels)
        })
    },
    async addHazardLevels({commit},hazard){
        const config = {
            url: api.api + `package/hazards/`,
            method: "POST",
            headers: {
                "Authorization": `Token ${localStorage.token}`
            },
            data:hazard
        }

        return await axios(config).then((req)=>{
            if(req.status ===200){
                let currentHazards = this.state.hazardLevels;
                currentHazards[req.data['uid']] = req.data;
                commit('setHazardLevels',currentHazards);
                return true;
            }
        }).catch(()=>{
            return false;
        })
    },
    async loadPackageTypes ({commit,dispatch}){
        const config = {
            url: api.api + `package/types/`,
            method: "GET",
            headers: {
                "Authorization": `Token ${localStorage.token}`
            },
        }
        let packageTypes = await dispatch("ListViewRequestToMap",config)
        commit("setPackageTypes",packageTypes);
    },
    async addNewPackageTypes({commit},newPackageType){
        const config = {
            url: api.api + `package/type/`,
            method: "POST",
            headers: {
                "Authorization": `Token ${localStorage.token}`
            },
            data:newPackageType
        }
        let newPackageTypeResponse = await  axios(config);
        if(newPackageTypeResponse.status===200 ||newPackageTypeResponse.status===201){
            let packs = state.packageTypes
            packs[newPackageTypeResponse.data["hazard"]["uid"]] = newPackageTypeResponse.data
            commit("setPackageTypes",packs)
            return true;
        }else {
            return false;
        }
    },
    async addNewPackage({commit},packageData){
        return await axios.post(api.api + `package/package/`, packageData, {
            headers: {
                'Content-Type': 'multipart/form-data;',
                "Authorization": `Token ${localStorage.token}`,
            }
        }).then((r)=>{
            if(r.status===200 || r.status ===201){
                let currentPackageList = state.packages;
                currentPackageList[r.data["uid"]] = r.data;
                commit;
                return true;
            }else{
                return false;
            }
        });
    },
    async addNewPackageImage({commit},data){
        console.log(data);
        const config = {
            url:api.api+`package/img/${data.package}/`,
            method:"POST",
            headers:{
                "Authorization": `Token ${localStorage.token}`,
                "media-type": "multipart/form-data",
                "content-type": 'application/json',
            },
            data:data
        }
        return await axios(config).then((r)=>{
            commit
            return r.status==100 ||r.status===201;
        })
    }

}
const getters = {
    getPackages:(state)=>state.packages,
    getHazardLevels: (state)=>state.hazardLevels,
    getPackageImages: (state)=> state.packageImages,
    getPackageTypes: (state) => state.packageTypes
}
export default {
    state,
    mutations,
    actions,
    getters
}