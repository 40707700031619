const dev = false

const ip = dev?'127.0.0.1': 'crow-api.maidport.co.za'
const port = dev?':8000':''
const socketHead = dev?'ws':'wss'
const header = dev?'http':'https'
const api = `${header}://${ip}${port}/`
const sock = `${socketHead}://164.92.194.201:8001/`

export default {
    api,
    sock
}
